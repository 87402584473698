import React, { useState, useEffect } from "react";

export function Widgets() {
    return (
        <div>Widgets</div>
    )
}

export function Header() {

    const [menuVisible, setMenuVisible] = useState(false);

    useEffect(() => {
        // Cambiar clase en el body al abrir/cerrar el menú
        if (menuVisible) {
        document.body.classList.add("mobile-menu-visible");
        } else {
        document.body.classList.remove("mobile-menu-visible");
        }
        // Limpiar la clase si el componente se desmonta
        return () => {
        document.body.classList.remove("mobile-menu-visible");
        };
    }, [menuVisible]);

    // Funciones para abrir y cerrar el menú
    const openMenu = () => setMenuVisible(true);
    const closeMenu = () => setMenuVisible(false);

    return (
        <div>
            {/* <div class="loader-wrap">
                <div class="preloader"><div class="preloader-close"></div></div>
                <div class="layer layer-one"><span class="overlay"></span></div>
                <div class="layer layer-two"><span class="overlay"></span></div>        
                <div class="layer layer-three"><span class="overlay"></span></div>        
            </div> */}

            <header class="main-header header-style-one">
                <div class="header-upper">
                    <div class="auto-container">
                        <div class="inner-container clearfix">
                            <div class="logo-box">
                                <div class="logo"><a href="/"><img src="assets/images/logo.png" alt="" style={{width: '150px'}}/></a></div>
                            </div>
                            <div class="nav-outer clearfix">
                                <div class="mobile-nav-toggler" onClick={openMenu}><img src="assets/images/icons/icon-bar.png" alt=""/></div>
                                <nav class="main-menu navbar-expand-md navbar-light">
                                    <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                        <ul class="navigation clearfix">
                                            <li><a href="/">Inicio</a>
                                                
                                            </li>
                                            <li class="dropdown"><a href="#">Servicios</a>
                                                <ul>
                                                    <li><a href="/consultoria-en-desarrollo-organizacional">CONSULTORIA EN DESARROLLO ORGANIZACIONAL</a></li>
                                                    <li><a href="/cobranza-judicial-y-extrajudicial">COBRANZA JUDICIAL Y EXTRAJUDICIAL</a></li>
                                                </ul>
                                            </li> 
                                            
                                            <li><a href="/contacto">Contacto</a></li>
                                            
                                            <li style={{paddingTop:'0px'}}>
                                                <a href="https://wa.me/5579236608" class="theme-btn btn-style-four" style={{paddingTop:'0px', paddingBottom:'0px'}}><span class="btn-title" style={{color:'#fff'}}><i class="flaticon-phone"></i>CONTACTANOS</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sticky-header">
                    <div class="header-upper">
                        <div class="auto-container">
                            <div class="inner-container clearfix">
                                <div class="logo-box">
                                    <div class="logo"><a href="/"><img src="assets/images/logo.png" alt="" style={{width: '150px'}}/></a></div>
                                </div>
                                <div class="nav-outer clearfix">
                                    <div class="mobile-nav-toggler"><img src="assets/images/icons/icon-bar.png" alt=""/></div>
                                    <nav class="main-menu navbar-expand-md navbar-light">
                                        <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                            <ul class="navigation clearfix">
                                                <li>
                                                    <a href="/">Inicio</a>
                                                </li>
                                                <li class="dropdown"><a href="#">Servicios</a>
                                                    <ul>
                                                        <li><a href="/consultoria-en-desarrollo-organizacional">CONSULTORIA EN DESARROLLO ORGANIZACIONAL</a></li>
                                                        <li><a href="/cobranza-judicial-y-extrajudicial">COBRANZA JUDICIAL Y EXTRAJUDICIAL</a></li>
                                                    </ul>
                                                </li> 
                                                <li>
                                                    <a href="/contacto">Contacto</a>
                                                </li>
                                                <li style={{paddingTop:'0px'}}>
                                                    <a href="https://wa.me/5579236608" class="theme-btn btn-style-four" style={{paddingTop:'0px', paddingBottom:'0px'}}><span class="btn-title" style={{color:'#fff'}}><i class="flaticon-phone"></i>CONTACTANOS</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {menuVisible && (
                    <div className="mobile-menu">
                    <div className="menu-backdrop" onClick={closeMenu}></div>
                    <div className="close-btn" onClick={closeMenu}>
                        <span className="icon flaticon-remove"></span>
                    </div>

                    <nav className="menu-box">
                        <div className="nav-logo">
                        <a href="/"><img src="#" alt="" title=""/></a>
                        </div>
                        <div className="menu-outer"></div>

                        <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                            <ul class="navigation clearfix">
                                <li>
                                    <a href="/">Inicio</a>
                                </li>
                                    <li><a href="/consultoria-en-desarrollo-organizacional">CONSULTORIA EN DESARROLLO ORGANIZACIONAL</a></li>
                                    <li><a href="/cobranza-judicial-y-extrajudicial">COBRANZA JUDICIAL Y EXTRAJUDICIAL</a></li>
                                <li>
                                    <a href="/contacto">Contacto</a>
                                </li>
                            </ul>
                        </div>
                        
                    </nav>
                    </div>
                )}
                <div class="nav-overlay">
                    <div class="cursor"></div>
                    <div class="cursor-follower"></div>
                </div>
            </header>
            {/* <a href="https://api.whatsapp.com/send?phone=5585659157" class="btn-wsp" target="_blank">
                <i class="fab fa-whatsapp"></i>
            </a> */}
        </div>
    )
}

export function Footer() {
    return(
        <div>
            <footer class="main-footer sp-two">
                <div class="auto-container">
                    <div class="footer-bottom">
                        <div class="row m-0 justify-content-between">
                            <ul class="menu">
                                <li><a href="assets/docs/aviso_de_privacidad_deudores.pdf" target="_blank">Aviso de privacidad </a></li>
                            </ul>
                            <div class="counter-title"><a style={{color: '#fff'}} href="https://goo.gl/maps/cjGTHESH5h5psfpMA?coh=178571&entry=tt">Avenida añil, Eje 3 Sur 571, colonia Granjas México, <br/>delegación Iztacalco, Ciudad de México, CDMX</a>
                        <br/><br/>
                        <i class="flaticon-phone"></i>  Telefono: <a style={{color: '#fff'}} href="tel:5533356846">(55) 3335-6846</a><br/>
                        <i class="flaticon-phone"></i>  Telefono: <a style={{color: '#fff'}} href="tel:5534005243">(55) 3400-5243</a>
                            </div>
                        </div>
                        <div class="copyright"><br/>Copyright © 2024  <a style={{color:' #c1a051'}} href="/">ADQUISICIONES & PASIVOS S.A DE C.V</a> </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Widgets